<template>
  <div>
    <v-card>
      <v-card-title class="subHeader grey lighten-3">Firma hinzufügen</v-card-title>
      <v-card-text>
        <v-form ref="form" v-model="valid">
          <v-row>
            <v-col cols="6">
              <v-text-field v-model="firma.name" color="black" :rules="rules.firma" label="Name"></v-text-field>
              <check-firma-name :items="firmen" :input="firma.name" />
            </v-col>
            <v-col cols="6">
              <v-text-field v-model="firma.straße" color="black" label="Straße"></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-text-field v-model="firma.plz" color="black" label="Plz"></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-text-field v-model="firma.ort" color="black" label="Ort"></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-text-field v-model="firma.additionalAddressInformation" color="black" label="Zusatz Adresse">
              </v-text-field>
            </v-col>
            <v-col cols="6">
              <v-text-field v-model="firma.telefon" color="black" label="Telefon"></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-autocomplete v-model="firma.countryId" :items="laender" item-text="name" item-value="id" label="Land"
                :rules="rules.land" color="black" persistent-h>
                <v-slide-x-reverse-transition slot="append-outer" mode="out-in">
                </v-slide-x-reverse-transition>
              </v-autocomplete>
            </v-col>
            <v-col cols="12">
              <v-text-field v-model="firma.bemerkung" color="black" label="Bemerkung"></v-text-field>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="accent" @click="createNewFirma()" dark>Speichern</v-btn>
        <v-btn color="black" outlined @click="updateDialog(false)">Abbrechen</v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
    export default {
        components: {
          'check-firma-name': () => import('../sub-components/check-firma-name')
        },

        data() {
            return {
                valid: false,

                rules: {
                    firma: [
                        v => !!v || 'Bitte Firmennamen eintragen'
                    ],
                    kategorie: [
                        v => !!v || 'Bitte katgeorie eintragen'
                    ],
                    land: [
                        v => !!v || 'Bitte Land eintragen'
                    ],
                },
                firma: {
                    name: '',
                    straße: '',
                    plz: '',
                    ort: '',
                    countryId: null,
                    telefon: '',
                    bemerkung: '',
                    additionalAddressInformation: ''
                }
            }
        },
        computed: {

        },
        mounted() {
        },
        props: ['firmen', 'dialog', 'laender'],
        methods: {
            updateDialog: function (value) {
                this.$refs.form.reset()
                this.$emit('update:dialog', value)
            },

            createNewFirma: function () {
                if (this.$refs.form.validate()) {
                    this.$http.post('/api/Stammdaten/CreateNewFirma', this.firma).then(response => {

                        this.firmen.push(response.data);
                        this.updateDialog(false);

                    }).catch((error) => console.log(error));
                }
            }
        }
    }
</script>

<style scoped>
</style>
